import Seo from "../components/seo"
import Layout from "../components/layout"
import React from "react"
import { ContactForm } from "../components/contactForm"
import SecondPageHeader from "../container/secondPageHaaderContainer"
import CallToAction from "../container/ctaContainer"

const Contact = () => {
  return (
    <Layout>
      <Seo title="お問い合わせ | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
      <SecondPageHeader englishTitle="- CONTACT -" mainTitle="お問い合わせ" subTitle="お気軽にお問い合わせください。" isShowTrialLink={false} />
      <div className="InquiryForm secondPageContens">
        <ContactForm/>
      </div>
      <CallToAction />
    </Layout>
  )
}

export default Contact