import React, { useState } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { setLocale } from "yup"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import { suggestive } from "yup-locale-ja"
import * as queryString from "querystring"
import { navigate } from "gatsby"
import {
  CLICK_TEXT_BOX_ACTION,
  CONTACT_FORM_CATEGORY,
  SELECT_RADIO_ACTION,
  SUBMIT_FORM_ACTION,
  trackingEvent
} from "../tools/Tracking"
import ReactLoading  from "react-loading";

setLocale(suggestive)

const schema = yup.object().shape({
  companyName: yup.string().required(),
  name: yup.string().required(),
  kanaName: yup.string(),
  departmentName: yup.string(),
  positionName: yup.string(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  trigger: yup.string(),
  message: yup.string().required(),
})

const TextInput = ({
                     controlId,
                     label,
                     name,
                     value,
                     onChange,
                     error,
                     required,
                     isInvalid,
                     onBlur,
                     className,
                     ...props
                   }) => (
  <Form.Group as={Row} className={"md-5 formGroup " + className} controlId={controlId}>
    <Col xs={10} sm={5} md={5} className="labelWrapper">
      <Form.Label className={!required && "notRequiredLabel"}>
        {label}
      </Form.Label>
      {required && (
        <Form.Label className={"requiredLabel"}>
          <span>必須</span>
        </Form.Label>
      )}
    </Col>

    <Col xs={12} md={7}>
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          onBlur={onBlur}
          onClick={() =>
            trackingEvent(
              CLICK_TEXT_BOX_ACTION,
              CONTACT_FORM_CATEGORY,
              name + "ContactForm"
            )
          }
          {...props}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </InputGroup>
    </Col>
  </Form.Group>
)

const Radio = ({ label, name, onChange, value, id }) => (
  <Form.Check
    type="radio"
    label={label}
    name={name}
    onChange={onChange}
    value={label}
    id={id}
    className="radio"
    onClick={() =>
      trackingEvent(
        SELECT_RADIO_ACTION,
        CONTACT_FORM_CATEGORY,
        name + "ContactForm"
      )
    }
  />
)

export const ContactForm = () => {
  const [state, setState] = useState({ isHttpError: false })
  return (
    <Row className="inquiryForm">
      <div className="col-sm-12 col-md-10 col-lg-7">
        <p>
          ワカチエにご興味をお持ちいただきありがとうございます。
          <br />
          以下ご入力の上送信ください。
          <br />
          追ってご連絡申し上げます。
        </p>
        <Formik
          validationSchema={schema}
          initialValues={{
            companyName: "",
            name: "",
            kanaName: "",
            departmentName: "",
            positionName: "",
            email: "",
            phone: "",
            trigger: "",
            message: "",
          }}
          onSubmit={async values => {
            const query = queryString.stringify(values)
            try {
              setState({ isProcessing: true });
              const result = await fetch(`/api/ContactTrigger?${query}`)
              if (400 <= result.status && result.status < 600) {
                setState({ isHttpError: true });
                return;
              }
              setState({ isProcessing: false });
            } catch (e) {
              setState({ isProcessing: false });
              setState({ isHttpError: true });
              return;
            }
            trackingEvent(SUBMIT_FORM_ACTION, CONTACT_FORM_CATEGORY)
            await navigate("/contact-result/")
          }}
        >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <TextInput
                controlId="companyName"
                label="貴社名"
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
                error={errors.companyName}
                onBlur={handleBlur}
                isInvalid={!!errors.companyName && touched.companyName}
                required={true}
                className={"inputItem"}
              />
              <TextInput
                controlId="name"
                label="ご担当者様名"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={errors.name}
                onBlur={handleBlur}
                isInvalid={!!errors.name && touched.name}
                required={true}
                className={"inputItem"}
              />
              <TextInput
                controlId="kanaName"
                label="ご担当者様名(カナ)"
                name="kanaName"
                value={values.kanaName}
                onChange={handleChange}
                className={"inputItem"}
              />
              <TextInput
                controlId="departmentName"
                label="部署名"
                name="departmentName"
                value={values.departmentName}
                onChange={handleChange}
                className={"inputItem"}
              />
              <TextInput
                controlId="positionName"
                label="役職"
                name="positionName"
                value={values.positionName}
                onChange={handleChange}
                className={"inputItem"}
              />
              <TextInput
                controlId="email"
                label="メールアドレス"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                onBlur={handleBlur}
                isInvalid={!!errors.email && touched.email}
                required={true}
                className={"inputItem"}
              />
              <TextInput
                controlId="phone"
                label="電話番号"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                error={errors.phone}
                onBlur={handleBlur}
                isInvalid={!!errors.phone && touched.phone}
                required={true}
                className={"inputItem"}
              />
              <Form.Group as={Row} className="md-5 inputItem">
                <Form.Label column sm={12} md={5}>
                  ワカチエをどこで知りましたか?
                </Form.Label>
                <Col sm={12} md={7}>
                  <Radio
                    label="展示会・セミナー"
                    name="trigger"
                    id="radio1"
                    value={values.trigger}
                    onChange={handleChange}
                  />
                  <Radio
                    label="Web記事等"
                    name="trigger"
                    id="radio2"
                    onChange={handleChange}
                    value={values.trigger}
                  />
                  <Radio
                    label="ソノリテ社員から"
                    name="trigger"
                    onChange={handleChange}
                    value={values.trigger}
                    id="radio3"
                  />
                  <Radio
                    label="同僚からの紹介"
                    name="trigger"
                    onChange={handleChange}
                    value={values.trigger}
                    id="radio4"
                  />
                  <Radio
                    label="知人・友人から"
                    name="trigger"
                    onChange={handleChange}
                    value={values.trigger}
                    id="radio5"
                  />
                  <Radio
                    label="SNS"
                    name="trigger"
                    onChange={handleChange}
                    value={values.trigger}
                    id="radio6"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="md-5 formGroup">
                <TextInput
                  controlId="message"
                  label="お問い合わせ内容"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  error={errors.message}
                  onBlur={handleBlur}
                  isInvalid={!!errors.message && touched.message}
                  required={true}
                  as="textarea"
                  rows={5}
                />
              </Form.Group>
              {state.isHttpError && (
                <div className="alert alert-danger" role="alert">
                  送信に失敗しました。 大変恐れ入りますが
                  <br />
                  sales@sonorite.co.jp
                  <br />
                  にご連絡いただけると幸甚です。
                </div>
              )}
              <Button
                type="submit"
                className="submitButton"
                onClick={() => console.log("on click submit button")}
              >
                送信
              </Button>
              {state.isProcessing && (
                <div className="loadingMessage">
                  <span className="title">SENDING...</span>
                  <ReactLoading
                    type="bars"
                    color="#0588B2"
                    height="50px"
                    width="50px"
                    className="mx-auto"
                  />
                  <span className="text">送信しています。しばしお待ちください。</span>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Row>
  )
}
